var exports = {};
/**
 *  @file highlight-copy.js
 *  @author Arron Hunt <arronjhunt@gmail.com>
 *  @copyright Copyright 2021. All rights reserved.
 */

/**
 * Adds a copy button to highlightjs code blocks
 */
class CopyButtonPlugin {
  /**
   * Create a new CopyButtonPlugin class instance
   * @param {Object} [options] - Functions that will be called when a copy event fires
   * @param {CopyCallback} [options.callback]
   * @param {Hook} [options.hook]
   * @param {String} [options.lang] Defaults to the document body's lang attribute and falls back to "en"
   */
  constructor(options = {}) {
    self.hook = options.hook;
    self.callback = options.callback;
    self.lang = options.lang || document.documentElement.lang || "en";
  }
  "after:highlightElement"({
    el,
    text
  }) {
    // Create the copy button and append it to the codeblock.
    let button = Object.assign(document.createElement("button"), {
      innerHTML: locales[lang]?.[0] || "Copy",
      className: "hljs-copy-button"
    });
    button.dataset.copied = false;
    el.parentElement.classList.add("hljs-copy-wrapper");
    el.parentElement.appendChild(button);

    // Add a custom proprety to the code block so that the copy button can reference and match its background-color value.
    el.parentElement.style.setProperty("--hljs-theme-background", window.getComputedStyle(el).backgroundColor);
    button.onclick = function () {
      if (!navigator.clipboard) return;
      let newText = text;
      if (hook && typeof hook === "function") {
        newText = hook(text, el) || text;
      }
      navigator.clipboard.writeText(newText).then(function () {
        button.innerHTML = locales[lang]?.[1] || "Copied!";
        button.dataset.copied = true;
        let alert = Object.assign(document.createElement("div"), {
          role: "status",
          className: "hljs-copy-alert",
          innerHTML: locales[lang]?.[2] || "Copied to clipboard"
        });
        el.parentElement.appendChild(alert);
        setTimeout(() => {
          button.innerHTML = locales[lang]?.[0] || "Copy";
          button.dataset.copied = false;
          el.parentElement.removeChild(alert);
          alert = null;
        }, 2000);
      }).then(function () {
        if (typeof callback === "function") return callback(newText, el);
      });
    };
  }
}

// Check if the NodeJS environment is available before exporting the class

exports = CopyButtonPlugin;

/**
 * Basic support for localization. Please submit a PR
 * to help add more languages.
 * https://github.com/arronhunt/highlightjs-copy/pulls
 */
const locales = {
  en: ["Copy", "Copied!", "Copied to clipboard"],
  es: ["Copiar", "\xA1Copiado!", "Copiado al portapapeles"],
  fr: ["Copier", "Copi\xE9 !", "Copi\xE9 dans le presse-papier"],
  de: ["Kopieren", "Kopiert!", "In die Zwischenablage kopiert"],
  ja: ["\u30B3\u30D4\u30FC", "\u30B3\u30D4\u30FC\u3057\u307E\u3057\u305F\uFF01", "\u30AF\u30EA\u30C3\u30D7\u30DC\u30FC\u30C9\u306B\u30B3\u30D4\u30FC\u3057\u307E\u3057\u305F"],
  ko: ["\uBCF5\uC0AC", "\uBCF5\uC0AC\uB428!", "\uD074\uB9BD\uBCF4\uB4DC\uC5D0 \uBCF5\uC0AC\uB428"],
  ru: ["\u041A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C", "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u043E!", "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u043E \u0432 \u0431\u0443\u0444\u0435\u0440 \u043E\u0431\u043C\u0435\u043D\u0430"],
  zh: ["\u590D\u5236", "\u5DF2\u590D\u5236!", "\u5DF2\u590D\u5236\u5230\u526A\u8D34\u677F"],
  "zh-tw": ["\u8907\u88FD", "\u5DF2\u8907\u88FD!", "\u5DF2\u8907\u88FD\u5230\u526A\u8CBC\u7C3F"]
};

/**
 * @typedef {function} CopyCallback
 * @param {string} text - The raw text copied to the clipboard.
 * @param {HTMLElement} el - The code block element that was copied from.
 * @returns {undefined}
 */
/**
 * @typedef {function} Hook
 * @param {string} text - The raw text copied to the clipboard.
 * @param {HTMLElement} el - The code block element that was copied from.
 * @returns {string|undefined}
 */
export default exports;